import React from "react";
import LoginCallback from "@okta/okta-react/dist/LoginCallback";
import Security from "@okta/okta-react/dist/Security";
import authService from "../../common/auth-service";

export default function ConnectedCallback(props) {
  if (typeof window === "undefined") return null;
  return (
    <Security authService={authService}>
      <LoginCallback {...props} />
    </Security>
  );
}
